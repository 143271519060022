import type { FC, ReactNode } from 'react'
import NextLink from 'next/link'
import type { TypeHref } from '@/schema'

type Props = {
  href: TypeHref,
  // arialabel: string,
  ariaDisabled?: boolean,
  rel?: 'alternate' | 'author' | 'bookmark' | 'help' | 'license' | 'me' | 'next' | 'prev' | 'search' | 'tag',
  prefetch?: boolean,
  locale?: string,
  title?: string,
  className?: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  children: ReactNode
}

export const Link: FC<Props> = ({ href, children, ariaDisabled, rel, locale, title, className, prefetch, target }) => {
  return (
    <NextLink href={href} passHref {...locale && { locale }} {...rel && { rel }} {...ariaDisabled && { 'aria-disabled': ariaDisabled }} {...title && { title }} {...className && { className }} {...prefetch && { prefetch }} {...target && { target }}>
      {children}
    </NextLink>
  )
}
