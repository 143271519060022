import { type FC, type MouseEvent, memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { Figure } from '.'
import { iconByName, type TypeMapIcon } from '@/utils'
import { font12_15, mq, vw } from '@/styles'

type TypeToast = 'info' | 'success' | 'warning' | 'error' | 'loading'
export interface iToastProps {
  type: TypeToast
  message: string
  closeNeeded?: boolean
  icon?: TypeMapIcon
  onClose?: (e?: MouseEvent<HTMLButtonElement>) => void
  timeout?: number
  key?: string | number
}

const getColorByType = (type: TypeToast) => {
  switch (type) {
    case 'info':
      return '#0374E0'
    case 'success':
      return '#70D02E'
    case 'warning':
      return '#FFCA00'
    case 'error':
      return '#EB005B'
    case 'loading':
      return '#0073E8'
    default:
      return '#E4E3DC'
  }
}

export const Toast: FC<iToastProps> = memo(({ message, type, icon, timeout = 5000, closeNeeded = false, onClose, key = 0 }) => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (!closeNeeded) {
      timer = setTimeout(() => {
        setVisible(false)
      }, timeout)
    }

    return () => { clearTimeout(timer) }
  }, [timeout, closeNeeded])

  const handleClose = (e:MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation() // Prevent the event from reaching the ToastContainer
    setVisible(false)
    if (onClose) onClose()
  }

  return (
    <AnimatePresence mode='wait'>
      {visible &&
        <Div key={key} $type={type} role='alert' aria-relevant='additions' variants={variants} initial='initial' animate='animate' exit='exit'>
          {icon && <Figure media={iconByName(icon as TypeMapIcon)} />}
          <p dangerouslySetInnerHTML={{ __html: message }} />
          {closeNeeded && <button onClick={handleClose} name='Cerrar' aria-label='Cerrar'>
            <Figure media={iconByName('search-clear')}/>
          </button>}
        </Div>
      }
    </AnimatePresence>
  )
})

const Div = styled(motion.div)<{ $type: TypeToast }>`
  ${font12_15(true, 400)}
  align-items: center;
  background-color: ${({ $type }) => getColorByType($type)};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: ${({ $type }) => ($type === 'warning' || $type === 'error') ? 'var(--color-text)' : 'var(--color-white)'};
  display: flex;
  justify-content: space-between;
  margin: ${vw(4, 'mobile')} 0;
  padding: ${vw(4, 'mobile')} ${vw(16, 'mobile')};
  pointer-events: auto;
  z-index: 999;

  ${mq.greaterThan('nexus7')} {
    margin: ${vw(4, 'tablet')} 0;
    padding: ${vw(4, 'tablet')} ${vw(16, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    ${font12_15(false, 400)}
    margin: ${vw(4, 'desktop')} 0;
    padding: ${vw(4, 'desktop')} ${vw(16, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin: 4px 0;
    padding: 4px 16px;
  }

  p {
    > span {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;

      > span {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      span {
        &.msg {
          padding-left: 15px;
        }
      }
    }
  }

  button {
    background: white;
    border-radius: 9px;
    height: 18px;
    margin-left: 15px;
    width: 18px;
  }
`

const variants = {
  initial: {
    opacity: 0,
    x: '100%',
    transition: { duration: .4 }
  },
  animate: {
    opacity: 1,
    x: '0%',
    transition: { duration: .4 }
  },
  exit: {
    opacity: 0,
    x: '100%',
    transition: { duration: .3 }
  }
}

export const ToastWrapper = styled.aside`
  bottom: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  user-select: none;
  transition: 300ms all linear;
  z-index: 200;
`
