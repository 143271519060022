import { NextResponse } from 'next/server'
import type { NextRequest } from 'next/server'
import { redirectBaseOnUserRole } from '@/utils'
// HOSTNAME, DEV

export const checkIfPublicPath = (pathname) => {
  if (pathname.startsWith('/login') ||
      pathname.startsWith('/signup') ||
      pathname.startsWith('/reset-password') ||
      pathname.startsWith('/404') ||
      pathname.startsWith('/not-found') ||
      pathname.startsWith('/maintenance')) {
    return true
  }
}

export const checkIfLegalPath = (pathname) => {
  if (pathname.startsWith('/rgpd') ||
      pathname.startsWith('/use-conditions') ||
      pathname.startsWith('/privacy-policy')) {
    return true
  }
}

function validateBasicAuth(header) {
  const authValue = header.split(' ')[1]
  const [user, pwd] = atob(authValue).split(':')
  return (user === process.env.BASIC_AUTH_USER && pwd === process.env.BASIC_AUTH_PASSWORD)
}

// This function can be marked `async` if using `await` inside
export function middleware(request: NextRequest) {
  // BASIC AUTH
  const basicAuthRequired = process.env.BASIC_AUTH_REQUIRED === 'true'
  const basicAuthHeader = request.headers.get('authorization') || request.headers.get('Authorization')
  const basicAuthPassed = !basicAuthHeader ? false : validateBasicAuth(basicAuthHeader)
  const nextUrl = request.nextUrl

  if (basicAuthRequired && !basicAuthPassed) {
    nextUrl.pathname = '/api/auth/basic'
    return NextResponse.rewrite(nextUrl)
  }

  // Redirect to maintenance page if the app is in maintenance mode
  const maintenance = process.env.DY_MAINTENANCE === 'true'
  if (maintenance) {
    if(request.nextUrl.pathname !== '/maintenance') {
      return NextResponse.redirect(new URL('/maintenance', request.url))
    } else {
      const response = NextResponse.next()
      return response
    }
  }

  const userCookie = request.cookies.get('DY_VIRBAC_USER')
  const isPublicPath = checkIfPublicPath(request.nextUrl.pathname)
  const isLegalPath = checkIfLegalPath(request.nextUrl.pathname)

  if(isPublicPath && userCookie) {
    const href = redirectBaseOnUserRole(userCookie, null, true)
    return NextResponse.redirect(new URL(href, request.url))
  } else if (!isPublicPath && !isLegalPath && !userCookie) {
    // ❌ Trying to access a private path without credentials'
    return NextResponse.redirect(new URL('/login', request.url))
  } else if (!isPublicPath && !isLegalPath  && userCookie) {
    //
  }
}

export const config = {
  matcher: [
    /*
     * Match all request paths except for the ones starting with:
     * - api (API routes)
     * - _next/static (static files)
     * - _next/image (image optimization files)
     * - favicon.ico (favicon file)
     */
    '/((?!api|_next/static|_next/image|favicon.ico|images).*)',
  ],
}
