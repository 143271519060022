'use client'
import { memo } from 'react'
import { useRouter } from 'next/navigation'
import styled from 'styled-components'


const ArrowBack = styled.button`
  align-items: center;
  border: none;
  display: flex;
  justify-content: center;
  margin-right: 15px;
  opacity: .4;
  outline: 0;
  transition: 300ms opacity ease-in-out;

  &:hover {
    opacity: 1;
  }
`

export const ButtonGoBack = memo(() => {
  const { back } = useRouter()

  return (
    <ArrowBack onClick={() => back()} title='Ir atrás'>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src='/images/svg/icon-back-arrow.svg' alt='Go Back icon'/>
    </ArrowBack>
  )
})
