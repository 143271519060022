import { useCallback, useEffect, useState } from 'react'

interface Breakpoints {
  mobile: number,
  tablet: number,
  desktopXs: number,
  desktop: number,
  getDeviceType: (width: number) => string
}

interface MediaInfo {
  size: {
    width: number | undefined
    height: number | undefined
  }
  deviceType: string
  isMobile?: boolean
  iOS?: boolean
  mobileDevice?: boolean
  mobileLandscape?: boolean
  isTouchDevice?: boolean
}

const BREAKPOINTS: Breakpoints = {
  mobile: 375,
  tablet: 768,
  desktopXs: 992,
  desktop: 1440,
  getDeviceType(width) {
    return width < this.tablet ? 'mobile' :
      width < this.desktopXs ? 'tablet' :
        'desktop'
  }
}

const checkUserAgent = () => {
  const UA = navigator.userAgent
  return UA ? Boolean(UA.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)) : false
}

export const iOS = () => {
  const UA = navigator.userAgent
  return UA ? Boolean(UA.match(/iPhone|iPad|iPod/i)) : false
}

const initialInfo: MediaInfo = {
  size: { width: undefined, height: undefined },
  deviceType: 'desktop',
  isMobile: false,
  iOS: false,
  mobileDevice: false,
  mobileLandscape: false,
  isTouchDevice: false
}
const getInfo = (isClient: boolean): MediaInfo => {
  return isClient ? {
    size: { width: window.innerWidth, height: window.innerHeight },
    deviceType: BREAKPOINTS.getDeviceType(window.innerWidth),
    isMobile: BREAKPOINTS.getDeviceType(window.innerWidth) === 'mobile',
    iOS: iOS(),
    mobileDevice: checkUserAgent(),
    mobileLandscape: screen?.orientation?.angle === 90,
    isTouchDevice: (('ontouchstart' in window) || (navigator.maxTouchPoints > 0))
  } : initialInfo
}

function debounce(func: Function, miliseconds) {
  let timer: number
  return function (event: any) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(func, miliseconds, event)
  }
}

export const useDeviceType = (miliseconds = 200) => {
  const [info, setInfo] = useState(getInfo(typeof window !== 'undefined'))

  const handleResize = useCallback(() => {
    const newInfo = getInfo(typeof window !== 'undefined')
    setInfo(newInfo)
  }, [setInfo])

  const debouncedHandler = debounce(handleResize, miliseconds)

  useEffect(() => {
    window.addEventListener('resize', debouncedHandler, { passive: true })
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', debouncedHandler)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return info
}
