import { useContext } from 'react'
import { TranslationsContext } from '@/context'
import type { TypeTranslationsJsonKeys } from '@/context'

export function useTranslator() {
  const { localization } = useContext(TranslationsContext)

  function t(keyValue: TypeTranslationsJsonKeys):string | number | (string|number)[] {
    const translation = `${keyValue}`.split('.').reduce((p,c) => p&&p[c] || null, localization.translations)

    if (!translation) {
      console.warn(`Translation key: '${keyValue}' for locale: '${localization?.locale}' not found.`)
      return null
    }

    if(typeof translation === 'string' || typeof translation === 'number' || Array.isArray(translation) || typeof translation === 'boolean') {
      return translation
    } else {
      console.warn(`Translation for key: '${keyValue}' has a NestedObj, please specify an inner key`)
      return ''
    }
  }

  return { t, locale: localization?.locale }
}
