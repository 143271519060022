export const months = {
  '1': 'ENE',
  '2': 'FEB',
  '3': 'MAR',
  '4': 'ABR',
  '5': 'MAY',
  '6': 'JUN',
  '7': 'JUL',
  '8': 'AGO',
  '9': 'SEP',
  '10': 'OCT',
  '11': 'NOV',
  '12': 'DIC'
}


// Used to go backwards like a clock with Quarters. saneModulus(-1, 4) => 3. Quarters are calculated at 0 index.
// Javascript doesn't like negative numbers in modulo
const saneModulus = (a, b) => ((a % b) + b) % b

// Current quarter if offset === true
type TypeQuarterNumber = ({ date?: Date, offset?: boolean })
const quarterNumber = ({ date = new Date(), offset = false }:TypeQuarterNumber) => {
  const monthIndex = date.getMonth()
  const quarterMonthLength = 3

  return Math.floor((monthIndex + quarterMonthLength - (offset ? 1 : 0)) / quarterMonthLength)
}

export const downloadableQuarterNumber = (date = new Date()) => {
  const numberOfQuarters = 4
  const offset = 1 // Since we're trying to calculate previous quarter but with +1 month for revisions, add offset
  return saneModulus(((quarterNumber({ date: date, offset: true }) - 1) - offset), numberOfQuarters) + 1
}

export const downloadableQuarterRange = (date = new Date()) => {
  const downloadableQuarter = downloadableQuarterNumber(date)
  const year = downloadableQuarter > quarterNumber({ date: date }) ? date.getFullYear() - 1 : date.getFullYear()

  return {
    start: new Date(year, downloadableQuarter * 3 - 3, 1),
    end: new Date(year, downloadableQuarter * 3, 0)
  }
}

export function getYearOfPreviousQuarter() {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() // (0-11)
  const currentYear = currentDate.getFullYear()

  // Determine the previous quarter's year (January, February, or March)
  // Previous quarter would be Q4 of the previous year
  // For all other cases, the previous quarter is in the same year
  return currentMonth < 3 ? currentYear - 1 : currentYear
}
