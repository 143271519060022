import type { FC, ReactNode, Dispatch, SetStateAction } from 'react'
import { usePathname } from 'next/navigation'
import { createContext, useEffect, useState } from 'react'
import { useMount } from '@/hooks'

type TypeSortBy = {
  name: string
  value: string
}

type TypeState = {
  filterQ: string
  sortBy: TypeSortBy
  filterBy: any
  setFilterQ: Dispatch<SetStateAction<string>>
  setSortBy: Dispatch<SetStateAction<TypeSortBy>>
  setFilterBy: Dispatch<SetStateAction<any>>
}

const initialState: TypeState = {
  filterQ: null,
  sortBy: null,
  filterBy: null,
  setFilterQ:  () => {},
  setSortBy:  () => {},
  setFilterBy:  () => {}
}

export const FiltersContext = createContext<TypeState | null>(initialState)

interface Props {
  children: ReactNode
}

let lastPathname = null
export const FiltersProvider: FC<Props> = ({ children }) => {
  const pathname = usePathname()
  // const searchParams = useSearchParams()
  // const q = searchParams.get('q')
  const isMount = useMount()

  const [filterQ, setFilterQ] = useState<string>(undefined)
  const [sortBy, setSortBy] = useState<TypeSortBy>(undefined)
  const [filterBy, setFilterBy] = useState<any>(undefined)


  useEffect(() => {
    if(!isMount) return
    if (lastPathname !== pathname) {
      lastPathname = pathname
      setFilterQ(undefined)
      setFilterBy(undefined)
    }
  }, [isMount, pathname])

  return <FiltersContext.Provider value={{
    filterQ, setFilterQ,
    sortBy, setSortBy,
    filterBy, setFilterBy
  }}>{children}</FiltersContext.Provider>
}
