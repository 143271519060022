import { css } from 'styled-components'
import { mq } from '.'

const rem = {
  baseMobile: 15,
  base: 18,
  get: (value:number, factor = null) => `${value / (factor ?? rem.baseMobile)}rem`
}

export const font10_12 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(10, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(12, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font12_15 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(12, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(15, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font13_18 = (mobile: boolean = true, weight: 400 | 500 | 600 = 400) => css`
  font-size: ${rem.get(13, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(18, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font14_21 = (mobile: boolean = true, weight: 400 | 500 | 600 = 400) => css`
  font-size: ${rem.get(14, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(21, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font22_28 = (mobile: boolean = true, weight: 400 | 500 | 600 = 400) => css`
  font-size: ${rem.get(22, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(28, rem[mobile ? 'baseMobile' : 'base'])};
`

export const getP10 = () => {
  return css`
    font-size: 10px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: 0px;
  `
}

export const getP12 = () => {
  return css`
    font-size: 12px;
    font-weight: normal;
    line-height: 15px;
    letter-spacing: 0px;
  `
}

export const getP13 = () => {
  return css`
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .5;
    line-height: 18px;
  `
}

export const getP14 = () => {
  return css`
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    letter-spacing: 0px;
  `
}

export const getP16 = () => {
  return css`
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
  `
}

export const getP18 = () => {
  return css`
    font-size: 18px;
    font-weight: normal;
    line-height: 25px;
    letter-spacing: 0px;
  `
}

export const getP20 = () => {
  return css`
    font-size: 20px;
    font-weight: normal;
    line-height: 28px;
    letter-spacing: 0px;
  `
}

export const getP25 = () => {
  return css`
    font-size: 25px;
    font-weight: normal;
    line-height: 31px;
    letter-spacing: 0px;
  `
}

export const getP28 = () => {
  return css`
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 1.06px;
    line-height: 33px;
  `
}

export const getP40 = () => {
  return css`
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 1.06px;
  `
}

export const getButton13 = () => {
  return css`
    font-size: 13px;
    font-weight: normal;
    letter-spacing: .5px;
    line-height: 18px;
  `
}

export const getFormPetit = () => {
  return css`
    font-size: 10px;
    font-weight: normal;
    letter-spacing: .2px;
    line-height: 10px;
  `
}

export const getFormLabel = () => {
  return css`
    font-size: 10px;
    font-weight: normal;
    letter-spacing: .83px;
    line-height: 10px;
    text-transform: uppercase;

    &.active {
      font-size: 18px;
      font-weight: normal;
      letter-spacing: 0px;
      line-height: 25px;
      text-transform: initial;
    }
  `
}

export const getFormValue = () => {
  return css`
    font-size: 18px;
    font-weight: normal;
    line-height: 25px;
    letter-spacing: 0px;
  `
}

export const getModal16 = () => {
  return css`
    font-size: 16px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0px;
  `
}

export const getModal27 = () => {
  return css`
    font-size: 27px;
    font-weight: normal;
    line-height: 37px;
    letter-spacing: 0px;
  `
}

export const getNavbarLink10 = () => {
  return css`
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: 10px;
    text-transform: uppercase;
  `
}

export const getNavbarUser = () => {
  return css`
    font-size: 10px;
    font-weight: normal;
    letter-spacing: .5px;
    line-height: 12px;
  `
}


export const getNavbarLink16 = () => {
  return css`
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: 16px;
  `
}

export const getNavbarLink20 = () => {
  return css`
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
  `
}

export const getIntro22 = () => {
  return css`
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
  `
}

export const getIntro32 = () => {
  return css`
    font-size: 32px;
    font-weight: 500;
    line-height: 37px;
  `
}

export const getP18InputLabel = () => {
  return css`
    font-size: ${rem.get(15)};
    font-weight: 500;
    line-height: ${rem.get(15)};
    letter-spacing: -.3px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(15, rem.base)};
      letter-spacing: -.36px;
    }
  `
}

export const getP18InputHasContentLabel = () => {
  return css`
    font-size: ${rem.get(12)};
    font-weight: 500;
    line-height: ${rem.get(10)};
    letter-spacing: -.3px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(10, rem.base)};
      letter-spacing: -.36px;
    }
  `
}
