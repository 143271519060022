import type { TypeCMSUserRole, TypeUserCookie } from '@/schema'
import { PRO } from '.'

type RequestCookie = {
  name: string;
  value: string;
}

type TypePrivateRedirectByRole = {
  [key in TypeCMSUserRole]: (vetId: string | number, multipleVets?:boolean, ) => string
}

type UserRoleObject = {
  [key in TypeCMSUserRole]: string
}

export const navLinksByRole = {
  'admin': ['vets', 'users', 'vet_corporate_users', 'commissions', 'orders'],
  'zone_manager': ['vets', 'vet_corporate_users'],
  'vet_manager': ['myvet_clinic', 'myvet_dashboard', 'myvet_recommendations', 'myvet_customers', 'myvet_invoices'],
  'vet_full_access': ['myvet_dashboard', 'myvet_recommendations', 'myvet_customers', 'myvet_invoices'],
  'vet_partial_access': ['myvet_recommendations', 'myvet_invite'],
}

// Homepage for each role
export const privateRedirectByRole: TypePrivateRedirectByRole = {
  'admin': () => '/',
  'zone_manager': () => '/',
  'vet_manager': (vetId, multipleVets) => multipleVets ? '/my-vets' : `/my-vet/${vetId}/dashboard`,
  'vet_full_access': (vetId, multipleVets) => multipleVets ? '/my-vets' : `/my-vet/${vetId}/dashboard`,
  'vet_partial_access': (vetId, multipleVets) => multipleVets ? '/my-vets' : `/my-vet/${vetId}/recommendations`,
}

export const roleName: UserRoleObject = {
  'admin': 'Virbac - Admin',
  'zone_manager': 'Virbac - Gerente de zona',
  'vet_manager': 'Vet - Admin',
  'vet_full_access': 'Vet',
  'vet_partial_access': 'Vet - Solo recetas',
  // 'biller': 'Solo facturación recetas clínica'
}

export const permissionsByRole = {
  'admin': ['/account', '', '/', '/user', '/vet', '/corporate-users', '/users', '/commissions', '/orders'],
  'zone_manager': ['/account', '', '/', '/user', '/vet', '/corporate-users', '/invoice'],
  'vet_manager': ['/account', '/recommendations', '/dashboard', '/clients', '/client', '/invoices', '/invoice', '/my-vet', '/my-vets', '/user'],
  'vet_full_access': ['/account', '/recommendations', '/dashboard', '/clients', '/client', '/invoices', '/invoice', '/my-vets', '/user'],
  'vet_partial_access': ['/account', '/recommendations', '/invite', '/my-vets']
}

export const roleOptionsAdminUsers = (userRole) => [
  userRole === 'admin' && { name: 'Virbac - Admin', value: 'admin' },
  ['admin', 'zone_manager'].includes(userRole) && { name: 'Virbac - Gerente de zona', value: 'zone_manager' },
].filter(Boolean)

export const roleOptionsVetUsers = (userRole) => [
  ['admin', 'zone_manager', 'vet_manager'].includes(userRole) && { name: 'Vet - Admin', value: 'vet_manager' },
  ['admin', 'zone_manager', 'vet_manager', 'vet_full_access'].includes(userRole) && { name: 'Vet', value: 'vet_full_access' },
  { name: 'Vet - Solo recetas', value:'vet_partial_access' }
].filter(Boolean)

export const getUserDataFromCookie = (userCookieValue:string):TypeUserCookie => JSON.parse(userCookieValue)
export const redirectBaseOnUserRole = (userCookie:RequestCookie, redirect, returnHref = false) => {
  if (!userCookie) return null
  // const userData: TypeUserCookie = getUserDataFromCookie(userCookie.value)

  // const role = userData?.user?.role
  // const localStorageVets = typeof window === 'undefined' ? null : localStorage.getItem(`dy_virbac_user_${userData.user.id}`)
  // const vets = JSON.parse(localStorageVets && localStorageVets !== 'undefined' ? localStorageVets : null)

  // console.log('🟡 vets', vets)

  // const vetId = userData?.user?.vet?.id ?? vets?.[0]?.id
  // console.log('🟡 vetId', vetId)

  const href = '/'
  // const href = privateRedirectByRole[role](vetId, (userData?.user?.vets > 1 || vets?.length > 1))
  if (returnHref) return href
  if (typeof redirect === 'function' && href) redirect(href)
}

export const VIRBAC_STORE = (clivirbac) => `https://${PRO ? 'store.' : 'sandbox.'}es.virbac.com/login.php?action=create_account&vet_id=${clivirbac}&vetid=${clivirbac}`
