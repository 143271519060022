'use client'
import { type ReactNode, type FC, type MouseEvent } from 'react'
import styled from 'styled-components'
import { getButton13 } from '@/styles'

type Props = {
  children: ReactNode
  className?: string
  disabled?: boolean
  id?: string
  onClick?: (e?:MouseEvent<HTMLButtonElement>) => void
  type?: 'button' | 'submit'
  state?: keyof typeof COLORS
}

const COLORS = {
  'active': null,
  'cancel': null,
  'invert': null,
  'pending': { bg: 'yellow', bgHover: '#F3C000' },
  'resend': { bg: 'yellow', bgHover: '#F3C000' },
  'csv': { bg: 'yellow', bgHover: '#F3C000' },
  'ready': { bg: 'green', bgHover: '#6AC62B' },
  'remove': { bg: 'red', bgHover: '#DF0056' },
  'delete': { bg: 'red', bgHover: '#DF0056' },
  'form': { bg: 'blue', bgHover: '#026ED4' },
  'pdf': { bg: 'blue', bgHover: '#026ED4' },
  'submit': { bg: 'navbar', bgHover: '#026ED4' },
}


export const Button:FC<Props> = ({ type = 'button', children, onClick, state = 'form', id = null, disabled = false, className = '' }) => {
  return (
    <ButtonStyled {...id && { id }} {...className && { className }} type={type} onClick={onClick} $state={state} disabled={disabled}>
      {children}
    </ButtonStyled>
  )
}

const ButtonStyled = styled.button<{ $state: keyof typeof COLORS }>`
  ${getButton13()}
  align-items: center;
  background: ${({ theme, $state }) => COLORS[$state]?.bg ? theme.colors[COLORS[$state]?.bg] : 'transparent'};
  border-radius: 100px;
  color: ${({ theme, $state }) => COLORS[$state] ? 'white' : theme.colors.navbar};
  display: flex;
  font-weight: 500;
  height: ${({ $state }) => COLORS[$state] === 'form' ? '50px' : '40px'};
  justify-content: center;
  min-width: 100px;
  padding: 0 20px;
  transition: 100ms background-color ease-in-out, 300ms box-shadow ease-in-out, 200ms opacity ease-in-out;
  width: auto;

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: none;
  }

  &:hover {
    background: ${({ $state }) => COLORS[$state]?.bgHover ?? 'transparent'};
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, ${({ $state }) => COLORS[$state] ? .05 : 0});
  }
`
