const defaultTheme = {
  colors: {
    'background': 'rgb(30, 30, 30)',
    'text': 'rgb(255, 255, 255)',
  },
  fonts: {
    // eslint-disable-next-line quotes
    'family': `'Montserrat', sans-serif`,
    // eslint-disable-next-line quotes
    'familyCode': `'Courier New', monospace`,
    'size': 16,
    'lineHeight': 24 / 16,
    'headersize': 20,
    'headerLineHeight': 24 / 20
  }
}

const themeProject = {
  colors: {
    'background': '#F6F6F4',
    'background_white': '#FFF',
    'background_login': '#E4E3DC',
    'navbar': '#014E98',
    'text': '#323232',
    'textSecondary': '#302E3B',
    'white': '#FFF',
    'whiteOpaque': '#FAF9F8',
    'white_d8': '#D8D8D8',
    'blue': '#0374E0',
    'blue_link': '#0073E8',
    'yellow': '#FFCA00',
    'green': '#70D02E',
    'red': '#EB005B',
    'grey': '#D7D7D7',
    'brown': '#918376',
    'input_base': '#0374E0',
    'input_error': '#EB005B',
    'input_black': '#000',
  },
  fonts: {
    primaryFont: 'Graphik, sans-serif'
  }
}

export const theme = {
  colors: {
    ...defaultTheme.colors,
    ...themeProject.colors
  },
  fonts: {
    ...defaultTheme.fonts,
    ...themeProject.fonts
  }
}

